function upDirRelativeLink(url) {
  const protocol = /^(ht|f)tp(s?)\:\/\//;
  if (protocol.test(url))
    return url;
  return "../" + url;
}
export function upDirTrack(tracklist) {
  return tracklist.map((track) => ({
    ...track,
    audio_link: upDirRelativeLink(track.audio_link),
    thumbnails: upDirRelativeLink(track.thumbnails)
  }));
}
export function updateAssetLinks(trackList2, language) {
  return language != null && language.length > 0 ? upDirTrack(trackList2) : trackList2;
}
export const trackList = [
  {
    track_title: "Eternal Sky",
    track_length: 157,
    author: "One Wave",
    playing: false,
    liked: true,
    audio_link: "tracks/403_full_eternal-sky_0157.mp3",
    thumbnails: "images/red-dress.jpg"
  },
  {
    track_title: "Brand New Start",
    track_length: 142,
    author: "Joseph",
    playing: false,
    liked: false,
    audio_link: "tracks/487_full_brand-new-start_0142.mp3",
    thumbnails: "images/small/ivan-diaz-YOy-ek-aBR0-unsplash.jpg"
  },
  {
    track_title: "Matcha to Go",
    track_length: 173,
    author: "Delicate Beats",
    playing: false,
    liked: false,
    audio_link: "tracks/159_full_matcha-to-go_0172.mp3",
    thumbnails: "images/small/erik-mclean-QYkxVBwNmoQ-unsplash.jpg"
  },
  {
    track_title: "Storm always passes",
    track_length: 160,
    author: "Colorfilm Music",
    playing: false,
    liked: false,
    audio_link: "tracks/447_full_storm-always-passes_0159.mp3",
    thumbnails: "images/small/torsten-dederichs-KrQJzrZiCak-unsplash.jpg"
  },
  {
    track_title: "Wane of Summer",
    track_length: 140,
    author: "Graceful Movement",
    playing: false,
    liked: false,
    audio_link: "tracks/531_full_wane-of-summer_0140.mp3",
    thumbnails: "red-dress.jpg"
  },
  {
    track_title: "The Fire Deep Within",
    track_length: 144,
    author: "Evan Mc Donald",
    playing: false,
    liked: false,
    audio_link: "tracks/322_full_the-fire-deep-within_0144.mp3",
    thumbnails: "images/small/mohamed-nohassi-_fWOxKmNBww-unsplash.jpg"
  },
  {
    track_title: "Jingle Bell Jazz Bells",
    track_length: 116,
    author: "Santa Claus",
    playing: false,
    liked: false,
    audio_link: "tracks/Jingle_Bell_Jazz_Bells.mp3",
    thumbnails: "images/small/annie-spratt-Z9Ds4w141i4-unsplash.jpg"
  },
  {
    track_title: "Romantic Deep House Music",
    track_length: 132,
    author: "OddVision",
    playing: false,
    liked: false,
    audio_link: "tracks/romantic_deep_house_music.mp3",
    thumbnails: "images/small/alexander-andrews--Bq3TeSBRdE-unsplash.jpg"
  },
  {
    track_title: "Eternal Sky",
    track_length: 157,
    author: "One Wave",
    playing: false,
    liked: true,
    audio_link: "tracks/403_full_eternal-sky_0157.mp3",
    thumbnails: "images/red-dress.jpg"
  }
];
