const de = {
  sendMessage: {
    success: "Danke, wir haben Ihre Nachricht erhalten und melden uns umgehend bei Ihnen.",
    failure: "Ihre Nachricht konnte leider nicht versendet werden."
  }
};
const en = {
  sendMessage: {
    success: "Thanks, we received your message and will get back to you shortly.",
    failure: "Unfortunately your message could not be sent."
  }
};
const userLang = navigator.language || navigator.userLanguage;
export const text = userLang.startsWith("de") ? de : en;
